:root {
  color-scheme: light only;
  supported-color-schemes: light only;
}

body {
  padding: 0 !important;
  margin: 0 auto !important;
  display: block !important;
  min-width: 100% !important;
  width: 100% !important;
  background: url("https://www.hapahapa.app/emails/bg_email.jpg");
  background-size: auto;
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}

p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
}

a {
  color: #000;
}

a.white {
  font-size: 14px;
  color: #fff;
}

.content-table {
  width: 80%;
  height: 100%;
  border: 0;
  border-spacing: 0px;
  padding: 0px;
}

.tr-white {
  background-color: #fff;
  padding: 0px;
}

.td-logo {
  padding-top: 40px;
  padding-bottom: 40px;
}

.td-first {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.td-second {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}

.text-white {
  color: #fff;
}

.cta-orange {
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  background-color: #ff7600;
  padding-top: 18px;
  padding-left: 30px;
  padding-bottom: 18px;
  padding-right: 30px;
  color: #fff;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #ff7600;
}

/* Mobile styles */
@media only screen and (max-device-width: 480px),
  only screen and (max-width: 480px) {
  .content-table {
    width: 90%;
    height: 100%;
    border: 0;
    border-spacing: 0px;
    padding: 0px;
  }

  .td-logo {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .td-first {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .td-second {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
}
